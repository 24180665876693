const header = document.getElementById("header");
const spNav = document.getElementById("spNav");
const hamburger = document.getElementById("hamburger");

// ハンバーガーメニュー
hamburger.addEventListener("click", () => {
  hamburger.classList.toggle("js-active--hamburger");
  hamburger.classList.toggle("js-hamburger-click");
  spNav.classList.toggle("js-active--nav");
});

// headerに背景を追加
window.addEventListener("scroll", () => {
  if (window.scrollY > 0) {
    header.classList.add("js-active--header");
    hamburger.classList.add("js-hamburger");
  } else if (window.scrollY == 0) {
    header.classList.remove("js-active--header");
    hamburger.classList.remove("js-hamburger");
  }
});
